import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useState,useEffect } from "react"
import axios from 'axios'
import {
  Form,
  FormGroup,
  FormControl,
  FormSelect,
  Button,
} from "react-bootstrap"
import { errorFindPartnerChannel } from "../../functions/errorFind"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/channel-partner/",
    label: "Channel Partner",
  },
]


const PartnersChanFormNew = () => {
  const [ip, setIP] = useState('');
const getData = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  console.log(res.data);
  setIP(res.data.IPv4)
}
useEffect( () => {
  getData()

}, [])
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({
    partnerName: "",
    agentName: "",
    partnerEmailAddress: "",
    email: "",
    company: "",
    targetedProductsC: "",
    cpDealRegUsers: "",
    message: "",
    cpMonBudgetAllocated: "",
    cpSecurityNeed: "",
    cpTimeline: "",
  })



  function submit(e) {
    e.preventDefault()
    const openErrors = errorFindPartnerChannel(fields)
    if (Object.keys(openErrors).length > 0) {
      setErrors(openErrors)
    } else {
      console.log("Processing")
      var xhr = new XMLHttpRequest()
      var url = "https://api.hsforms.com/submissions/v3/integration/submit/7763866/e082d334-711b-4c87-bf33-eb6cba59e0fe"

      /* eslint-disable-next-line */
      var hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")

      var data = {
        fields: [
          {
            name: "partner_name",
            value: fields.partnerName,
          },
          {
            name: "agent_name",
            value: fields.agentName,
          },
          {
            name: "partner_email_address",
            value: fields.partnerEmailAddress,
          },
          {
            name: "email",
            value: fields.email,
          },
          {
            name: "company",
            value: fields.company,
          },
          {
            name: "targeted_products__c",
            value: fields.targetedProductsC,
          },
          {
            name:"cp_deal_reg_users",
            value: fields.cpDealRegUsers,
          },
          {
            name: "cp_mon_budget_allocated",
            value: fields.cpMonBudgetAllocated,
          },
          {
            name: "cp_security_need",
            value: fields.cpSecurityNeed,
          },
          {
            name: "cp_timeline",
            value: fields.cpTimeline,
          },
          {
            name: "message",
            value: fields.message,
          }
        ],
        context: {
          /* eslint-disable-next-line */
          hutk: document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
          ipAddress: ip.value,
          pageUri: "vigilantnow.com/channel-partners/register-deal-new/",
          pageName: "Channel Partner - Deal Registration"
        }
      } 

      var final_data = JSON.stringify(data)

      xhr.open("POST", url, hutk)
      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          window.location.href = "/us/da468XPdV17lJD9PT3wk65"
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          console.log(xhr.responseText) // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          console.log(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          console.log(xhr.responseText) //Returns a 404 error if the formGuid isn't found
        }
      }

      // Sends the request

      xhr.send(final_data)
    }
  }

  return (
    <Layout>
      <Seo title="VIGILANT CHANNEL PARTNERS" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <section className="section-title-top--with-form grid--bg --for-it-ops-2">
        <div className="container">
          <div className="row">
            <div className="col-md-8 pt-5 mx-auto">
              <h1>
              REGISTER A DEAL
              </h1>
              <h4 style={{ color: "#3EB3E3" }}>VIGILANT CHANNEL PARTNERS</h4>
              <p className="content pb-5">
              Your one stop shop for for marketing materials and registering deals.
              </p>
              <div className="box">
                <Form>
                <Form.Group className="mb-3 mt-3" controlId="formPartnerEmailAddress">
                  
                  <span>Partner Information<hr/></span>
                </Form.Group>
                  <Form.Group className="mb-3" controlId="formPartnerName">
                    <Form.Control
                      value={fields.partnerName}
                      className="bg-white"
                      type="fName"
                      placeholder="Partner Company Name"
                      onChange={e =>
                        setFields({ ...fields, partnerName: e.target.value })
                      }
                    />

                    <span>{errors.partnerName}</span>
                  </Form.Group>

                  <FormGroup className="mb-3" controlId="formAgentName">
                    <FormControl
                      value={fields.agentName}
                      className="bg-white"
                      type="lName"
                      placeholder="Partner Contact Name"
                      onChange={e =>
                        setFields({ ...fields, agentName: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.agentName}</span>
                  </FormGroup>

                  <Form.Group className="mb-3" controlId="formPartnerEmailAddress">
                    <Form.Control
                      value={fields.partnerEmailAddress}
                      className="bg-white"
                      type="email"
                      placeholder="Partner Email Address"
                      onChange={e =>
                        setFields({ ...fields, partnerEmailAddress: e.target.value })
                      }
                    />

                    <span>{errors.partnerEmailAddress}</span>
                  </Form.Group>

                  <Form.Group className="mb-3 mt-3" >
                  
                    <span>Prospect Information<hr/></span>
                  </Form.Group>


                  <FormGroup className="mb-3" controlId="formBasicEmail">
                    <FormControl
                      value={fields.email}
                      className="bg-white"
                      type="email"
                      placeholder="Prospect Email Address"
                      onChange={e =>
                        setFields({ ...fields, email: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.email}</span>
                  </FormGroup>

                  <FormGroup className="mb-3" controlId="formCompany">
                    <FormControl
                      value={fields.company}
                      className="bg-white"
                      type="jobTitle"
                      placeholder="Prospect Company Name"
                      onChange={e =>
                        setFields({ ...fields, company: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.company}</span>
                  </FormGroup>
                 
                 
                  <FormGroup className="mb-3" controlId="formTargetedProductsC">
                        <FormSelect
                          value={fields.targetedProductsC}
                          className="bg-white"
                          type="reason"
                          placeholder="Services Interested In"
                          onChange={e =>
                            setFields({ ...fields, targetedProductsC: e.target.value })
                          }
                        >
                          <option value="">Services Interested In</option>
                          <option value="MDR">MDR</option>
                          <option value="NDR">NDR</option>
                          <option value="EDR">EDR</option>
                          <option value="VDR">VDR</option>
                          <option value="V365">V365</option>
                          <option value="Pen Testing">Pen Testing</option>
                        </FormSelect>
                    <span type="invalid">{errors.targetedProductsC}</span>
                  </FormGroup>
                  
                  <FormGroup className="mb-3" controlId="formCpDealRegUsers">
                    <FormControl
                      value={fields.cpDealRegUsers}
                      className="bg-white"
                      type="jobTitle"
                      placeholder="Number of Users"
                      onChange={e =>
                        setFields({ ...fields, cpDealRegUsers: e.target.value })
                      }
                    />
                    <span type="invalid">{errors.cpDealRegUsers}</span>
                  </FormGroup>

                      <Form.Group className="mb-3 mt-3" >
                        <span>Prospect Qualification<hr/></span>
                      </Form.Group>

                      <FormGroup className="mb-3" controlId="formCpMonBudgetAllocated">
                        <FormSelect
                          value={fields.cpMonBudgetAllocated}
                          className="bg-white"
                          type="reason"
                          placeholder="Monthly Security Budget Allocated"
                          onChange={e =>
                            setFields({ ...fields, cpMonBudgetAllocated: e.target.value })
                          }
                        >
                          <option value="">Monthly Security Budget Allocated</option>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </FormSelect>
                        <span type="invalid">{errors.cpMonBudgetAllocated}</span>
                     </FormGroup>

                     <FormGroup className="mb-3" controlId="formCpSecurityNeed">
                        <FormSelect
                          value={fields.cpSecurityNeed}
                          className="bg-white"
                          type="reason"
                          placeholder="Security Need"
                          onChange={e =>
                            setFields({ ...fields, cpSecurityNeed: e.target.value })
                          }
                        >
                          <option value="">Security Need</option>
                          <option value="Compliance Audit">Compliance/Audit</option>
                          <option value="Security Visibility">Security Visibility</option>
                          <option value="Proactive Responses SOC">Proactive Responses - 24/7 SOC</option>
                          <option value="Replacing Another Vendors">Replacing Another Vendors</option>
                          <option value="Other">Other</option>
                        </FormSelect>
                    <span type="invalid">{errors.cpSecurityNeed}</span>
                    </FormGroup>

                    <FormGroup className="mb-3" controlId="formCpTimeline">
                        <FormSelect
                          value={fields.cpTimeline}
                          className="bg-white"
                          type="reason"
                          placeholder="Timeline"
                          onChange={e =>
                            setFields({ ...fields, cpTimeline: e.target.value })
                          }
                        >
                          <option value="">Timeline</option>
                          <option value="0-30 days">0-30 days</option>
                          <option value="31-89 days">31-89 days</option>
                          <option value="3-6 months">3-6 months</option>
                          <option value="6 months+">6 months+</option>
                        </FormSelect>
                    <span type="invalid">{errors.cpTimeline}</span>
                    </FormGroup>

                    <FormGroup className="mb-3" controlId="formMessage">
                        <FormControl
                          value={fields.message}
                          className="bg-white"
                          type="message"
                          as="textarea"
                          rows={5}
                          placeholder="Notes"
                          onChange={e =>
                            setFields({ ...fields, message: e.target.value })
                          }
                        />
                        <span type="invalid">{errors.message}</span>
                      </FormGroup>

                      <FormGroup className="mb-3 d-none" controlId="ipAddress">
                        <FormControl
                          value={fields.ipAddress}
                          className="bg-white"
                          type="ipAddress"
                          as="textarea"
                          rows={5}
                          placeholder="10.10.0.1"
                          onChange={e =>
                            setFields({ ...fields, ipAddress: e.target.value })
                          }
                        />
                        
                      </FormGroup>
                      

                  <FormGroup>
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                      }}
                      className="nav-section--cta-btn"
                      variant="primary"
                      type="submit"
                      onClick={submit}
                    >
                      <h4>REGISTER DEAL</h4>
                    </Button>
                   
                  </FormGroup>
                  
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
      </div>
      <div>
      </div>
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

export default PartnersChanFormNew
